import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tax/"
            }}>{`All-Stars Tax-Efficient`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-vix-spritz/"
            }}>{`VIX Spritz`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-market-vane/"
            }}>{`Market Vane`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: aggressive growth, optimized for tax efficiency`}</li>
        <li parentName="ul">{`Type: trend-following strategy`}</li>
        <li parentName="ul">{`Asset classes: complex and leveraged ETPs tracking stocks and bonds`}</li>
        <li parentName="ul">{`Rebalancing: daily`}</li>
        <li parentName="ul">{`Taxation: 40% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $5,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Four-Fifteen`}</em>{` aims to combine the advantages of tactical asset allocation with tax efficiency. This proprietary TuringTrader strategy uses complex leveraged ETPs to hedge its stock market exposure, depending on the current market regime. While technically, `}<em parentName="p">{`Four-Fifteen`}</em>{` follows a daily rebalancing schedule, it changes its asset allocation only infrequently, resulting in meager maintenance requirements. These properties make `}<em parentName="p">{`Four Fifteen`}</em>{` an excellent choice for investors with taxable accounts.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Four-Fifteen: A tax-efficient strategy",
                "title": "TuringTrader.com, Four-Fifteen: A tax-efficient strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/tax-efficient-investing/"
            }}>{`Tax-Efficient Investing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/market-vane/"
            }}>{`Market Vane Indicator`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Four-Fifteen`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`during bull markets, hold S&P 500`}</li>
        <li parentName="ul">{`during bear markets, hold 60% S&P 500 and the remainder in triple-leveraged inverse S&P 500, and triple-leveraged U.S. Treasuries`}</li>
        <li parentName="ul">{`switch between market regimes using TuringTrader's Market Vane indicator`}</li>
      </ul>
      <p>{`Thanks to using an inverse-leveraged ETP to hedge the exposure to the S&P 500, `}<em parentName="p">{`Four-Fifteen`}</em>{` can hold onto 60% of S&P 500 shares throughout. In doing so, the strategy combines tactical asset allocation with tax-efficiency.`}</p>
      <p>{`For a detailed discussion of the inner workings, investors are encouraged to `}<a parentName="p" {...{
          "href": "/articles/tax-efficient-investing/"
        }}>{`read our background article`}</a>{`.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`While `}<em parentName="p">{`Four-Fifteen`}</em>{` may hold up to three ETPs at a time, its net exposure toggles between only two asset classes: the U.S. stock market and U.S. Treasuries. This concept of `}<em parentName="p">{`serial diversification`}</em>{` successfully avoids concentration risks. However, holding only a single asset class at a given time results in a tail risk identical to that of the respective asset class.`}</p>
      <p>{`Based on Monte-Carlo simulations of historical returns, `}<em parentName="p">{`Four-Fifteen`}</em>{`'s risk profile is almost identical to that of a passive 60/40 portfolio.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`Four-Fifteen`}</em>{` handily beats the 60/40 benchmark in most years. As a result, the strategy continually gains over its benchmark, with only brief periods of underperformance.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`To meet its tax-efficient objective, `}<em parentName="p">{`Four-Fifteen`}</em>{` is severely limited in its trading activity. We specifically designed the strategy to be used in the context of a meta-portfolio, namely `}<a parentName="p" {...{
          "href": "/portfolios/tt-all-stars-tax/"
        }}>{`All-Stars Tax-Efficient`}</a>{`.`}</p>
      <p><em parentName="p">{`Four-Fifteen`}</em>{` trades infrequently, on a schedule set by `}<a parentName="p" {...{
          "href": "/dashboard/market-vane/"
        }}>{`Market Vane`}</a>{`. These trades only affect 40% of the portfolio value, leading to a tax burden similar to that of a passive 60/40. The strategy works best in taxable accounts. If tax-efficiency is not of significant concern, other portfolios most likely offer better risk-adjusted returns.`}</p>
      <p>{`To hold up to three ETFs with their required weighting, `}<em parentName="p">{`Four-Fifteen`}</em>{` requires a minimum investment of $5,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, February 2021`}</a>{`: Initial release.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, October 2022`}</a>{`: Replace TMF with `}<a parentName="li" {...{
            "href": "/portfolios/heine-bond-model/"
          }}>{`Heine's Bond Model`}</a>{` to better cope with rising rates.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      